



















import { Api, SubscriptionActionInfo } from '@/edshed-common/api'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Mixins } from 'vue-property-decorator'
import Timeline from './views/components/Timeline.vue'

@Component({
  name: 'SubscriptionActions',
  components: { Timeline }
})
export default class SubscriptionProducts extends Mixins(ComponentHelper) {
  actions: SubscriptionActionInfo[] = []

  async mounted () {
    this.actions = await Api.getSubscriptionActions({})
  }
}

